import { addItem } from "./utils";
import './styles/main.scss';

console.log("Hello world");

addItem("Hello world!");


let output = document.getElementById('output2');

let navPage1 = document.getElementById('nav-page1')
let navPage2 = document.getElementById('nav-page2')
let navPage3 = document.getElementById('nav-page3')

let page1 = document.getElementById('page1')
let page2 = document.getElementById('page2')
let page3 = document.getElementById('page3')

navPage1.addEventListener('click', event => {
    removeShowClass();
    page1.classList.add("show");
})

navPage2.addEventListener('click', event => {
    removeShowClass();
    page2.classList.add("show");
})

navPage3.addEventListener('click', event => {
    removeShowClass();
    page3.classList.add("show");
})


let removeShowClass = function() {
    let pagesArray = [page1, page2, page3];

    pagesArray.forEach((elem) => {
        elem.classList.remove("show");
    })
}
